<template>
  <layout-full>
    <router-view />
    <loading />
  </layout-full>
</template>

<script>
import LayoutFull from "@core/layouts/layout-full/LayoutFull.vue";
import Loading from "@/components/loading.vue";

export default {
  components: {
    LayoutFull,
    Loading,
  },
};
</script>
